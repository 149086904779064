<template lang="pug">
div
  om-table(
    :items.sync="campaigns"
    :columns="columns"
    :columnClasses="columnClasses"
    :allItemsCount="campaignCount"
    :filterable="true"
    :pagination="pagination"
    :sorting="sorting"
    :isSearchResult="isFiltered"
    :loadingContent="loadingCampaigns"
    @selectedIdsChange="$emit('selectedIdsChange', $event)"
    @sortingChange="$emit('sortingChange', $event)"
    @paginationChange="$emit('paginationChange', $event)"
    @mouseOutFromTableRow="closeKebabMenu($event)"
  )
    template(slot="table-filter-row")
      slot(name="table-filter-row")
        .brand-table-filter-inner.col-sm-12.col-lg-8.col-xl-8.d-flex
          .brand-table-filter-col.campaign-table-filter-col
            label.mb-0.mr-3(for="domain") Domain:
            multiselect.slim-multiselect(
              v-model="selectedDomains"
              :options="domains"
              label="domain"
              :limit="1"
              :limit-text="(count) => $t('multiSelectLimitText', { count })"
              track-by="_id"
              :show-labels="false"
              :closeOnSelect="true"
              :allow-empty="true"
              :multiple="true"
              :placeholder="$t('select')"
            )
          .brand-table-filter-col.campaign-table-filter-col.campaign-table-filter-col-devices
            label.mb-0.mr-3(for="devices") {{ $t('devices') }}:
            multiselect(
              v-model="filter.device"
              :options="['desktop_and_mobile', 'desktop', 'mobile']"
              :show-labels="false"
              :closeOnSelect="true"
              :searchable="false"
              :allow-empty="true"
              :placeholder="$t('any')"
            )
              template(slot="singleLabel" slot-scope="props")
                .d-flex.align-items-center.font-size-0--75
                  om-device.w-3.flex-grow-0.mr-2(:type="props.option")
                  span.option__desc.flex-grow-1
                    span.option__title.font-size-0--75 {{ $t(`device.${props.option === 'desktop_and_mobile' ? 'both' : props.option}`) }}
              template(slot="option" slot-scope="props")
                .d-flex.align-items-center
                  om-device.w-3.flex-grow-0.mr-3(:type="props.option")
                  span.option__desc.flex-grow-1
                    span.option__title {{ $t(`device.${props.option === 'desktop_and_mobile' ? 'both' : props.option}`) }}
          .brand-table-filter-col.campaign-table-filter-col.campaign-table-filter-col-active-only
            .form-check
              input#activeOnly.form-check-input(
                type="checkbox"
                true-value="active"
                false-value=""
                v-model="filter.status"
                @change="activeFilter"
              )
              label.form-check-label.mb-0(for="activeOnly") {{ $t('activeOnly') }}
        .brand-table-filter-inner.brand-table-filter-inner-small.d-flex.col-12.col-sm-12.col-lg-4.col-xl-4
          .brand-table-filter-col.campaign-table-filter-col.px-0
            input#searchText.form-control.form-control-search(
              type="text"
              :placeholder="$t('searchInputPlaceholder')"
              v-model="filter.search"
            )
          .brand-table-filter-col.campaign-table-filter-col.campaign-table-date-filter.pl-0
            om-tooltip.w-100(transition="fade" theme="light" placement="bottom")
              span(v-html="$t('multiCampaigns.tooltip.statistics')")
              template(slot="trigger")
                om-period-select#campaignDateRange(
                  v-model="filter.dateRange"
                  :options="dateRangeOptions"
                  :minDate="minimumSelectableDate"
                  :maxDate="maximumSelectableDate"
                  @input="setDateRange"
                  :locale="$i18n.locale"
                  size="medium"
                )
    template(slot="table-embedded-content")
      slot(name="table-embedded-content")
    template(slot="preview" slot-scope="{ rowData }")
      router-link(:to="{ name: 'campaign_variants', params: { id: rowData.row.id } }")
        variant-preview(
          :key="rowData.row.id"
          @observable="addObservable($event.$el)"
          :allowSsr="!!rowData.row.templateType"
          @inited="updateDimensions"
          :dimensions="boxDimensions"
          :campaign="rowData.row"
          :transparentOverlay="false"
          :templateType="rowData.row.templateType"
        )
    template(slot="name" slot-scope="{ rowData }")
      .d-flex.align-items-center
        router-link(:to="{ name: 'campaign_variants', params: { id: rowData.row.id } }") {{ rowData.row.name }}
        schedule-tooltip(
          v-if="rowData.row.schedule && rowData.row.schedule.from"
          :schedule="rowData.row.schedule"
        )
      .d-flex.align-items-center
        a.cursor-pointer.campaign-domain.mr-2(
          :href="`http://${rowData.row.domain}`"
          target="_blank"
        ) {{ rowData.row.domain | removeProtocol }}

    template(slot="status" slot-scope="{ rowData }")
      om-chip(v-if="campaigns[rowData.index].status === 'archived'" color="secondary")
        span.text-secondary {{ $t('archived') }}
        template(#icon)
          BulkArchiveSvg(color="#8F97A4")
      toggle-button.mb-0.mt-0(
        v-else
        :class="{ inactive: hasScheduleFrom(campaigns, rowData.index), 'in-experiment': rowData.row.currentExperimentName }"
        :value="campaigns[rowData.index].status === 'active'"
        :key="campaigns[rowData.index]._id"
        :sync="true"
        :labels="{ checked: hasScheduleFrom(campaigns, rowData.index) ? $t('scheduled') : $t('active'), unchecked: $t('inactive') }"
        :style="isToggleInactive(rowData)"
        :width="88"
        :height="24"
        :margin="1"
        :ref="'toggle_' + rowData.row._id"
        @change.self="toggleCampaignStatus(rowData.row._id, $event.value ? 'active' : 'inactive')"
      )
      template(v-if="rowData.row.status === 'active' && noActiveVariant(rowData.row.variants)")
        om-tooltip.ml-2
          span {{ $t('inactiveVariants') }}
          template(slot="trigger")
            uil-exclamation-circle(size="1.5rem" color="#E4252D")
      .ml-2.d-flex(v-if="rowData.row.alerts || rowData.row.currentExperimentName")
        template(v-if="rowData.row.alerts")
          alert-tooltip.mr-2(
            :style="{ visibility: rowData.row.alerts ? 'visible' : 'hidden' }"
            :alerts="rowData.row.alerts || []"
          )
      om-tooltip(v-if="rowData.row.currentExperimentName")
        .campaign-experiment-info {{ $t('experiments.campaignPartOfExperiment', { experimentName: rowData.row.currentExperimentName }) }}
        template(slot="trigger")
          UilFlask(size="1.5rem" color="#505763")

    template(slot="device" slot-scope="{ rowData }")
      om-device.cursor-pointer(
        :type="rowData.row.device"
        @click.native="goToCampaign(rowData.row.id)"
      )
    template(slot="impressions" slot-scope="{ rowData }")
      div {{ rowData.row.impressions | thousandSep }}
    template(slot="conversions" slot-scope="{ rowData }")
      div {{ rowData.row.conversions | thousandSep }}
    template(slot="conversionRate" slot-scope="{ rowData }")
      div {{ rowData.row.conversionRate | dec2 }}
    template(slot="createdAt" slot-scope="{ rowData }")
      div {{ formatDate(rowData.row.createdAt) }}
    template(slot="priority" slot-scope="{ rowData }")
      .d-flex.justify-content-end.align-items-center
        .priority-icons
          chevron-double-up(:size="12" v-if="rowData.row.priority === 'HIGH'")
          chevron-double-down(:size="12" v-if="rowData.row.priority === 'LOW'")
    template(slot="kebabMenu" slot-scope="{ rowData }")
      slot(name="kebabMenu" :rowData="rowData")
        om-kebab-menu.campaign-kebab-menu(
          :rowData="rowData"
          @delete="$emit('delete', [rowData.row._id])"
          @edit="$router.push({ name: 'campaign_variants', params: { id: rowData.row.id } })"
          @duplicate="$emit('duplicate', rowData.row._id)"
          @rename="renameCampaign"
        )
          template(slot="duplicate")
            span {{ $t('duplicate') }}
          template(slot="rename")
            span {{ $t('rename') }}
          template(slot="delete")
            span {{ $t('delete') }}
          template(slot="edit")
            span {{ $t('editCampaign') }}
          template(slot="custom")
            .kebab-option.kebab-edit-schedule(
              :class="{ disabled: rowData.row.status === 'archived' }"
              @click="rowData.row.status !== 'archived' ? $emit('openScheduleModal', rowData.row) : ''"
            ) {{ $t('editSchedule') }}
            .kebab-option.kebab-archive.border-top(
              v-if="rowData.row.status !== 'archived'"
              @click="$emit('archive', [rowData.row._id])"
            ) {{ $t('archive') }}
            .kebab-option.kebab-restore.border-top(
              v-if="rowData.row.status === 'archived'"
              @click="$emit('restore', [rowData.row._id])"
            ) {{ $t('restore') }}
            .kebab-option.kebab-share-campaign(
              v-if="hasCampaignShareFeature && !isDynamicContent(rowData.row) && !rowData.row.currentExperimentName"
              @click="shareCampaign(rowData.row)"
            ) {{ $t('share') }}
            popper.kebab-priority(
              v-if="!isEmbedded(rowData.row) && !isDynamicContent(rowData.row)"
              trigger="hover"
              :ref="`menu_popper_priority_${rowData.row._id}`"
              :options="{ placement: 'left' }"
            )
              .popper.shadow-sm
                .priority-setting-link(@click="priorityChanged('HIGH', rowData.row.id)")
                  chevron-double-up(:size="12")
                  span.pr-1.col-8 {{ $t('campaignPrioritySettings.high') }}
                  check.ml-2(
                    v-if="rowData.row.priority === 'HIGH'"
                    width="14"
                    height="14"
                    color="#8F97A4"
                  )
                .priority-setting-link(@click="priorityChanged('NORMAL', rowData.row.id)")
                  span(:style="{ width: '12px', height: '12px' }")
                  span.pr-1.col-8 {{ $t('campaignPrioritySettings.normal') }}
                  check.ml-2(
                    v-if="!rowData.row.priority || rowData.row.priority === 'NORMAL'"
                    width="14"
                    height="14"
                    color="#8F97A4"
                  )
                .priority-setting-link(@click="priorityChanged('LOW', rowData.row.id)")
                  chevron-double-down(:size="12")
                  span.pr-1.col-8 {{ $t('campaignPrioritySettings.low') }}
                  check.ml-2(
                    v-if="rowData.row.priority === 'LOW'"
                    width="14"
                    height="14"
                    color="#8F97A4"
                  )
              .kebab-option(slot="reference")
                span {{ $t('campaignPrioritySettings.setPriority') }}
                chevron-right
    template(slot="loading")
      skeleton-loader(type="campaign" :rows="skeletonRowNumber")
  CampaignRenameModal(@update-campaign-name="updateCampaignName")
</template>

<script>
  import Vue from 'vue';
  import { get as _get } from 'lodash-es';
  import OmDevice from '@/components/OmDevice.vue';
  import CHANGE_VARIANT_STATUS from '@/graphql/ChangeVariantStatus.gql';
  import GET_ACCOUNT_DOMAINS from '@/graphql/GetAccountDomains.gql';
  import dateFormat from '@/mixins/dateFormat';
  import ScheduleTooltip from '@/components/Campaign/ScheduleTooltip.vue';
  import campaignTable from '@/mixins/campaignTable';
  import upgradePlanModal from '@/mixins/upgradePlanModal';
  import ssrMixin from '@/mixins/ssr';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import campaignShareMixin from '@/mixins/campaignShare';
  import { mapGetters } from 'vuex';
  import IndicatorX from '@/assets/admin/svg/indicatorx.vue';
  import IntegrationErrorIndicator from '@/components/IntegrationErrorIndicator';
  import moment from 'moment';

  import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue';
  import ChevronDoubleDown from '@/components/Svg/Icons/ChevronDoubleDown.vue';
  import ChevronDoubleUp from '@/components/Svg/Icons/ChevronDoubleUp.vue';
  import ChevronRight from '@/components/Svg/Icons/ChevronRight.vue';
  import Check from '@/components/Svg/Icons/CheckSvg.vue';
  import VariantPreview from '@/components/Template/VariantPreview.vue';
  import AlertTooltip from '@/components/Campaign/AlertTooltip.vue';
  import BulkArchiveSvg from '@/assets/admin/svg/BulkArchiveSvg.vue';
  import dateRange from '@/mixins/dateRange';
  import closeKebabMenu from '@/components/Elements/KebabMenu/closeKebabMenu';
  import { UilFlask, UilExclamationCircle } from '@iconscout/vue-unicons';
  import CampaignRenameModal from '@/components/Modals/CampaignRename.vue';

  export default {
    components: {
      OmDevice,
      IndicatorX,
      IntegrationErrorIndicator,
      SkeletonLoader,
      ChevronDoubleDown,
      ChevronDoubleUp,
      Check,
      ChevronRight,
      VariantPreview,
      AlertTooltip,
      BulkArchiveSvg,
      UilFlask,
      ScheduleTooltip,
      UilExclamationCircle,
      CampaignRenameModal,
    },
    mixins: [
      dateFormat,
      upgradePlanModal,
      campaignTable,
      ssrMixin,
      observableCollectionMixin,
      dateRange,
      closeKebabMenu,
      campaignShareMixin,
    ],

    props: {
      campaigns: { type: Array, required: true },
      campaignCount: { type: Number, required: true },
      pagination: { type: Object, required: true },
      sorting: { type: Object, required: true },
      defaultFilter: { type: Object },
      loadingCampaigns: { type: Boolean, default: false },
    },

    data() {
      return {
        columnClasses: {
          name: 'text-left brand-campaigns-name',
          status: 'brand-campaigns-status',
          preview: 'brand-campaigns-preview',
          device: 'brand-campaigns-devices',
          impressions: 'brand-campaigns-results',
          conversions: 'brand-campaigns-results',
          conversionRate: 'brand-campaigns-results',
          createdAt: 'brand-campaigns-created',
          priority: 'brand-campaigns-priority',
          kebabMenu: 'brand-campaigns-kebab-menu',
        },
        filter: {
          domainIds: [],
          device: '',
          search: '',
          status: '',
          ...JSON.parse(JSON.stringify(this.defaultFilter)),
        },
        domains: [],
        selectedDomains: [],
        activeOnly: false,
        filterFirstFetch: true,
        blurWindowHandler: null,
        ssrBoxSelector: '.template-preview',
      };
    },

    computed: {
      ...mapGetters(['isActiveShopifyDomain', 'brandName', 'getLocale', 'hasCampaignShareFeature']),
      isFiltered() {
        return (
          (this.filter.domainIds && this.filter.domainIds.length !== 0) ||
          this.filter.device !== '' ||
          this.filter.search !== '' ||
          this.activeOnly
        );
      },
      columns() {
        const cols = [
          { header: '', key: 'preview' },
          { header: this.$t('campaign'), key: 'name' },
          { header: this.$t('status'), key: 'status' },
          { header: this.$t('devices'), key: 'device' },
          { header: this.$t('impressions'), key: 'impressions' },
          { header: this.$t('conversions'), key: 'conversions' },
          { header: this.$t('conversionRate'), key: 'conversionRate' },
          { header: this.$t('dateCreated'), key: 'createdAt' },
          { header: '', key: 'priority' },
          { header: '', key: 'kebabMenu' },
        ];

        return cols;
      },
      skeletonRowNumber() {
        return this.campaignCount > 30 ? 30 : this.campaignCount ? this.campaignCount : 5;
      },
    },
    watch: {
      filter: {
        handler() {
          if (this.pagination.page > 1) {
            this.$emit('paginationChange', { page: 1, limit: 30 });
          }
          this.$emit('filterChange', this.filter);
          this.$bus.$emit('deselectAllTable');
        },
        deep: true,
      },
      selectedDomains: {
        handler() {
          if (!this.filterFirstFetch) {
            this.filter.domainIds = this.selectedDomains.map((d) => d._id);
          }
          this.filterFirstFetch = false;
        },
        deep: true,
      },
      campaigns: {
        handler(n) {
          if (n && n.length) {
            this.$nextTick(() => {
              this.updateDimensions();
            });
          }
        },
        deep: true,
      },
    },

    async created() {
      this.moment = moment;
      const {
        data: { accountDomains },
      } = await this.$apollo.query({
        query: GET_ACCOUNT_DOMAINS,
      });
      this.domains = accountDomains;
      this.selectedDomains = this.defaultFilter.domainIds
        ? this.domains.filter((d) => this.defaultFilter.domainIds.includes(d._id))
        : [];
    },

    methods: {
      renameCampaign(rowData) {
        this.$modal.show('campaign-rename', {
          campaignId: rowData.id,
          campaignName: rowData.name,
        });
      },
      updateCampaignName({ campaignId, campaignName }) {
        this.$emit('nameChanged', { campaignId, newCampaignName: campaignName });
      },
      isCampaignArchived(rowData) {
        return this.campaigns[rowData.index].status === 'archived';
      },
      hasMultipleVariants(rowData) {
        return this.campaigns[rowData.index].variants.length > 1;
      },
      setDateRange(event) {
        const { interval, key } = event;
        if (key === 'allTime') {
          this.filter.dateRange = { interval };
          return;
        }

        const from = moment(interval.from).format(this.dateRangeDateFormat);
        const to = moment(interval.to).format(this.dateRangeDateFormat);
        this.filter.dateRange = { interval: { from, to } };
      },
      activeFilter(e) {
        this.activeOnly = e.target.checked;
      },

      getConversion(sub, imp) {
        return sub && imp ? `${((sub / imp) * 100).toFixed(2)} %` : `${0} %`;
      },

      campaignStatusClass(status) {
        return {
          'campaign-status-active': status === 'active',
          'campaign-status-inactive': status === 'inactive',
        };
      },

      async toggleVariantStatus(campaignId, variantId, status, schedule) {
        const scheduleFrom = _get(schedule, 'from', '') || '';
        await this.$apollo.mutate({
          mutation: CHANGE_VARIANT_STATUS,
          variables: {
            variantId,
            status,
          },
        });

        for (let i = 0; i < this.campaigns.length; i++) {
          if (this.campaigns[i]._id === campaignId) {
            for (let j = 0; j < this.campaigns[i].variants.length; j++) {
              if (this.campaigns[i].variants[j]._id === variantId) {
                Vue.set(this.campaigns[i].variants[j], 'status', status);
                const hasActiveVariant = this.campaigns[i].variants.some(
                  (variant) => variant.status === 'active',
                );
                if (!hasActiveVariant && !scheduleFrom) {
                  this.toggleCampaignStatus(campaignId, 'inactive');
                }
                break;
              }
            }
            break;
          }
        }
      },

      onDeviceChange(device) {
        this.filter.device = device.value;
      },
      priorityChanged(priority, campaignId) {
        this.$emit('priorityChanged', { priority, campaignId });
      },
      async shareCampaign({ _id, id: campaign }) {
        this.closeKebabMenu({ _id });
        await this._shareCampaign(campaign);
      },
      isDynamicContent(campaign) {
        return campaign?.templateType === 'dynamic_content';
      },
      isEmbedded(campaign) {
        return campaign?.templateType === 'embedded';
      },
    },
  };
</script>
<style lang="sass">
  .campaign-kebab-menu
    .kebab-option-duplicate
      order: 1
    .kebab-option-edit
      order: 2
    .kebab-option-delete
      order: 7
    .kebab-edit-schedule
      order: 3
    .kebab-restore, .kebab-archive
      order: 5
    .kebab-priority
      order: 4
    .kebab-share-campaign
      order: 6
</style>
