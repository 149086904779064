<template lang="pug">
.brand-wrapper.brand-campaigns
  bulk-operation(
    :items.sync="selectedCampaigns"
    :isSelectedCampaignNew="isSelectedCampaignNew"
    :is-archive.sync="isBulkArchiveOperationEnabled"
    :is-restore.sync="isBulkRestoreOperationEnabled"
    @refetchQuery="fetchCampaigns"
    @duplicate="duplicateCampaign"
    @delete="deleteCampaigns"
    @archive="archiveCampaigns"
    @restore="restoreCampaigns"
  )
  .container-fluid.px-3
    .row.mb-6
      .col-9.align-self-center
        om-heading.mt-1(h1) {{ title }}
      .col-3.d-flex.justify-content-end.align-items-center
        om-button(primary :to="routeCampaignCreate" tag="button") {{ $t('newCampaign') }}
    template(v-if="!hasAnyActiveOrInactiveCampaign && !loadingCampaigns")
      empty-state.m-5(
        :text1="$t('emptyState.campaignList.text1')"
        :text2="$t('emptyState.campaignList.text2')"
        :imageUrl="require('@/assets/empty-state/campaign-list.png')"
        @show="campaignEmptyStateShowed"
      )
        template(#underText)
          om-button(primary @click="handleEmptyStateCTAClick" tag="button") {{ $t('emptyState.campaignList.button') }}
    template(v-else)
      campaign-table.campaign-table.pt-2(
        :campaigns="campaigns"
        :campaignCount="campaignCount"
        :loadingCampaigns="loadingCampaigns"
        :pagination="pagination"
        :sorting="sorting"
        :defaultFilter="filter"
        @selectedIdsChange="selectedCampaignIds = $event"
        @filterChange="filter = $event"
        @paginationChange="paginationChange($event)"
        @sortingChange="sorting = $event"
        @duplicate="duplicateCampaign"
        @delete="deleteCampaigns"
        @archive="archiveCampaigns"
        @restore="restoreCampaigns"
        @openScheduleModal="openScheduleModal"
        @priorityChanged="changeCampaignPriority"
        @nameChanged="changeCampaignName"
      )
        template(slot="table-embedded-content")
          embedded-campaign(:campaign-id="2037")
        template(#table-filter-row)
          form.brand-table-filter-inner.col-12.d-flex.align-content-start.row.no-gutters(
            ref="filterForm"
          )
            .campaign-table-filter-col.multi-campaign-table-filter-col.multi-campaign-table-filter-col-search.col-6.col-lg-2.pl-0.pr-3.py-1
              om-input#campaignSearch.w-100(
                v-model="filterModel.search"
                small
                @input="lazySearch"
                :placeholder="$t('search')"
                type="text"
              )
            .campaign-table-filter-col.multi-campaign-table-filter-col.col-6.col-lg-2.pl-0.pr-3.px-xxxl-3.py-1
              om-select#selectStatus.w-100(
                v-model="filterModel.status"
                deSelectable
                size="small"
                :options="statusOptions"
                optionText="text"
                @click.native="hideArchivedStatusPopover"
                :placeholder="$t('multiCampaigns.selectStatus')"
                @input="setFilterModel({ key: 'status', eventKey: 'value' }, $event)"
              )
              popper(
                v-if="showArchivedCampaignsPopover"
                key="campaignsStatusSelectPopper"
                :force-show="true"
                trigger="click"
                :options="{ placement: 'bottom' }"
                transition="fade"
                enter-active-class="fade-enter-active"
                leave-active-class="fade-leave-active"
              )
                .popper.mt-3.brand-tooltip.brand-tooltip-light.ml-n1(style="margin-bottom: -4px")
                  feature-info#campaingsStatusPopperContent.w-100(
                    @close="hideArchivedStatusPopover"
                  )
                    template(slot="title") {{ $t('multiCampaigns.selectStatusPopperTitle') }}
                    template(slot="text") {{ $t('multiCampaigns.selectStatusPopperText') }}
                div(slot="reference")
            .campaign-table-filter-col.multi-campaign-table-filter-col.col-6.col-lg-2.pl-0.pr-3.px-xxxl-3.py-1
              om-select#selectDomain.w-100(
                v-model="filterModel.domainIds"
                deSelectable
                size="small"
                :options="domainOptions"
                optionText="text"
                :searchable="true"
                :placeholder="$t('multiCampaigns.selectDomain')"
                @input="setFilterModel({ key: 'domainIds', eventKey: 'value' }, $event)"
              )
            .campaign-table-filter-col.multi-campaign-table-filter-col.col-6.col-lg-2.pl-0.pr-3.px-xxxl-3.py-1
              om-select#selectDevice.w-100(
                v-model="filterModel.device"
                deSelectable
                size="small"
                :options="deviceOptions"
                optionText="text"
                :placeholder="$t('multiCampaigns.selectDevice')"
                @input="setFilterModel({ key: 'device', eventKey: 'value' }, $event)"
              )
                template(#prepend-option="{ option }")
                  om-device.w-3.flex-grow-0.mr-2(:type="option.key")
            .campaign-table-filter-col.multi-campaign-table-filter-col.d-flex.align-items-center.col-auto.pl-0.pr-3.px-xxxl-3.py-1
              #campaignFilterClearAll.brand-link(
                v-if="isShowClearAllBtn"
                @click="resetFilterModel"
                style="white-space: nowrap"
              ) {{ $t('clearAll') }}
            .campaign-table-filter-col.multi-campaign-table-filter-col.multi-campaign-table-filter-col-date-range.col-6.col-lg-2.ml-xl-auto.pl-lg-1.pr-lg-1.py-1
              om-tooltip.w-100(transition="fade" theme="light" placement="left")
                span(v-html="$t('multiCampaigns.tooltip.statistics')")
                template(slot="trigger")
                  om-period-select#multiCampaignDateRange(
                    :value="selectedDateRange"
                    :options="dateRangeOptions"
                    @input="setFilterModel({ key: 'dateRange' }, $event)"
                    :minDate="minimumSelectableDate"
                    :maxDate="maximumSelectableDate"
                    :locale="getLocale"
                    size="small"
                  )

    CarouselTemplateRecommendation
    schedule(
      v-if="currentCampaign"
      :schedule="currentCampaign.schedule"
      :campaignId="currentCampaign.id"
      @changeSchedule="fetchCampaigns()"
      @deleteSchedule="deleteScheduleFromCampaigns()"
    )
  template-preview
  NewCampaign
  NotificationToast(:duration="3000" :width="374")
    template(slot-scope="props")
      span.font-size-0--75(v-if="props.item.data.toast.color === 'success'") {{ $t('notify.campaigns.restoreSuccess') }}
      span.font-size-0--75(v-if="props.item.data.toast.color === 'danger'") {{ $t('notify.campaigns.restoreFailed') }}
  DeleteCampaign(@experiments:delete-campaign-in-experiment="deleteCampaignsInExperiment")
  ArchiveCampaign(@experiments:archive-campaign-in-experiment="archiveCampaignsInExperiment")
</template>

<script>
  import CampaignTable from '@/components/CampaignTable.vue';
  import BulkOperation from '@/components/BulkOperation.vue';
  import EmptyState from '@/components/EmptyState.vue';
  import CarouselTemplateRecommendation from '@/components/Wizard/CarouselTemplateRecommendation';
  import ALL_CAMPAIGNS from '@/graphql/AllCampaigns.gql';
  import { debounce, omit, update } from 'lodash-es';
  import COPY_CAMPAIGN from '@/graphql/CopyCampaign.gql';
  import DELETE_CAMPAIGNS from '@/graphql/DeleteCampaigns.gql';
  import ARCHIVE_CAMPAIGNS from '@/graphql/ArchiveCampaigns.gql';
  import HAS_ANY_ACTIVE_OR_INACTIVE_CAMPAIGN from '@/graphql/HasAnyActiveOrInactiveCampaign.gql';
  import { getAccountIdFromCookie } from '@/util';
  import { getBrandedClassString } from '@/components/Elements/Button';
  import Schedule from '@/components/Modals/Schedule.vue';
  import campaignSetting from '@/mixins/campaignSetting';
  import campaignSchedule from '@/mixins/campaignSchedule';
  import TemplatePreview from '@/components/Modals/TemplatePreview';
  import NewCampaign from '@/components/Modals/NewCampaign.vue';
  import GET_ACCOUNT_DOMAINS from '@/graphql/GetAccountDomains.gql';
  import OmDevice from '@/components/OmDevice';
  import { arrayify } from '@om/common/src/utils';
  import { track } from '@/services/xray';
  import RESTORE_CAMPAIGNS from '@/graphql/RestoreCampaigns.gql';
  import dateRange, { dateRangeDateFormat, getDateRangeOptions } from '@/mixins/dateRange';
  import { mapGetters, mapMutations } from 'vuex';
  import NotificationToast from '@/components/Notify/NotificationToast.vue';
  import DeleteCampaign from '@/components/Experiments/Modal/DeleteCampaign.vue';
  import ArchiveCampaign from '@/components/Experiments/Modal/ArchiveCampaign.vue';
  import { ssStore, ssRetrieve } from '@/helpers/sessionStorage';
  import FeatureInfo from '@/components/Popovers/FeatureInfo.vue';
  import cookieMixin from '@/mixins/cookie';
  import EmbeddedCampaign from '@/components/EmbeddedCampaign.vue';
  import campaignCreateMixin from '@/mixins/newCampaignFlow/campaignCreate';

  const SESSION_FILTER_KEY_PREFIX = 'campaignFilter';
  const COOKIE_CAMPAIGN_ARCHIVE_FLAG = 'x-om-campaign-archive';

  const _clone = (v) => JSON.parse(JSON.stringify(v));
  const initFilterModel = ({ additionalFields } = {}) => ({
    domainIds: { key: '', text: '' },
    device: { key: '', text: '' },
    status: { key: '', text: '' },
    search: null,
    ...additionalFields,
  });
  const initFilter = ({ additionalFields } = {}) => ({
    domainIds: null,
    device: null,
    status: null,
    search: null,
    ...additionalFields,
  });

  export default {
    components: {
      NotificationToast,
      CampaignTable,
      EmptyState,
      BulkOperation,
      CarouselTemplateRecommendation,
      Schedule,
      TemplatePreview,
      NewCampaign,
      OmDevice,
      DeleteCampaign,
      ArchiveCampaign,
      EmbeddedCampaign,
      FeatureInfo,
    },
    mixins: [campaignSetting, campaignSchedule, dateRange, cookieMixin, campaignCreateMixin],
    apollo: {
      hasAnyActiveOrInactiveCampaign: {
        query: HAS_ANY_ACTIVE_OR_INACTIVE_CAMPAIGN,
      },
      fetchCampaignData: {
        query: ALL_CAMPAIGNS,
        variables() {
          let filter = JSON.parse(JSON.stringify(this.filter));
          filter = omit(filter, ['dateRange.key', 'dateRange.value']);
          return {
            pagination: this.pagination,
            sorting: this.sorting,
            filter: {
              ...filter,
              domainIds: arrayify(filter?.domainIds),
            },
            experimentDetails: true,
          };
        },
        result({ data }) {
          const { campaigns, count } = data.campaignData;
          this.loadingCampaigns = false;
          this.campaigns = _clone(campaigns);
          this.campaignCount = count;
          if (this.isCampaignDelete && count === 0) {
            this.isCampaignDelete = false;
            this.$bus.$emit('resetTableHadItems');
          }
        },
        error() {
          this.loadingCampaigns = false;
        },
        manual: true,
      },
    },
    data() {
      return {
        requestDateFormat: 'YYYY-MM-DD',
        campaigns: [],
        campaignCount: 0,
        showArchivedCampaignsPopover: false,
        campaignData: {},
        loadingCampaigns: true,
        loadingDomainList: true,
        isCampaignDelete: false,
        selectedCampaignIds: [],
        currentCampaign: null,
        sorting: { field: 'createdAt', direction: -1 },
        pagination: { page: 1, limit: 30 },
        filter: initFilter(),
        filterModel: initFilterModel(),
        dateRangeDefaultValue: {
          dateRange: {
            value: getDateRangeOptions(dateRangeDateFormat)[1].value,
            key: getDateRangeOptions(dateRangeDateFormat)[1].key,
            interval: {
              from: getDateRangeOptions(dateRangeDateFormat)[1].interval.from,
              to: getDateRangeOptions(dateRangeDateFormat)[1].interval.to,
            },
          },
        },
        hasAnyActiveOrInactiveCampaign: null,
        domains: [],
        statusOptions: [
          {
            key: 'active',
            value: 'active',
            text: this.$t('active'),
          },
          {
            key: 'inactive',
            value: 'inactive',
            text: this.$t('inactive'),
          },
          {
            key: 'archived',
            value: 'archived',
            text: this.$t('archived'),
          },
        ],
        deviceOptions: [
          {
            key: 'desktop_and_mobile',
            value: 'desktop_and_mobile',
            text: this.$t(`device.desktop_and_mobile`),
          },
          {
            key: 'desktop',
            value: 'desktop',
            text: this.$t(`device.desktop`),
          },
          {
            key: 'mobile',
            value: 'mobile',
            text: this.$t(`device.mobile`),
          },
        ],
        filterModelKeyMap: [
          { key: 'search' },
          { key: 'status', eventKey: 'value', option: 'statusOptions' },
          { key: 'domainIds', eventKey: 'value', option: 'domainOptions' },
          { key: 'device', eventKey: 'value', option: 'deviceOptions' },
          // key for query.params
          {
            key: 'dateRange.interval.from',
            eventKey: 'dateRange.interval.from',
            option: 'dateRangeOptions',
          },
          {
            key: 'dateRange.interval.to',
            eventKey: 'dateRange.interval.to',
            option: 'dateRangeOptions',
          },
          // key for session storage
          { key: 'dateRange', eventKey: null, option: 'dateRangeOptions' },
        ],
        archivedPopperOptions: {
          placement: 'bottom',
          html: true,
          container: 'body',
        },
      };
    },
    computed: {
      ...mapGetters(['getLocale', 'hasAccountFeature']),
      title() {
        const campaignsTitle = `${this.$t('campaigns')}`;
        if (!this.campaignCount) return campaignsTitle;

        return `${campaignsTitle} (${this.campaignCount})`;
      },
      loading() {
        return this.loadingDomainList || this.loadingCampaigns;
      },
      selectedDateRange() {
        return this.filterModel.dateRange;
      },
      isBulkRestoreOperationEnabled() {
        return this.selectedCampaigns.every((c) => c.status === 'archived');
      },
      isBulkArchiveOperationEnabled() {
        return this.selectedCampaigns.every((c) => c.status !== 'archived');
      },
      selectedCampaigns() {
        return this.campaigns
          .filter((c) => this.selectedCampaignIds.indexOf(c._id) > -1)
          .map((c) => {
            return {
              _id: c._id,
              status: c.status,
              version: c.version,
            };
          });
      },
      isSelectedCampaignNew() {
        return this.selectedCampaigns.length !== 0 && this.selectedCampaigns[0].version !== 1;
      },
      domainOptions() {
        return this.domains.map((domain) => ({
          key: domain._id,
          value: domain._id,
          text: domain.domain,
        }));
      },
      isDatePickerOnDefaultState() {
        return (
          this.filter?.dateRange?.interval?.from === this.dateRangeOptions[1].interval.from &&
          this.filter?.dateRange?.interval?.to === this.dateRangeOptions[1].interval.to
        );
      },
      isShowClearAllBtn() {
        const { dateRange, ...restOfTheFilter } = this.filter;
        return Object.keys(restOfTheFilter).some((key) => this.filter[key]);
      },
    },
    watch: {
      loading(state) {
        if (!state) this.showAdminLoader(false);
      },
      sorting: {
        handler() {
          this.fetchCampaigns();
          this.$store.commit('updateTableSorting', this.sorting);
          this.updateBrowserUrl({ ...this.$route.query, ...this.filter, ...this.sorting });
        },
        deep: true,
      },
      filter: {
        handler() {
          if (!this.pagination.page) {
            this.paginationChange({ page: 1, limit: this.pagination.limit });
          } else {
            this.debounceFilterQuery();
          }
          ssStore(this.getStoredFilterKey(), { ...this.$route.query, ...this.filter });
          this.updateBrowserUrl({ ...this.$route.query, ...this.filter });
        },
        deep: true,
      },
    },
    mounted() {
      this.$nextTick().then(() => {
        const filtersFromStorage = ssRetrieve(this.getStoredFilterKey());
        const filtersFromQuery = this.$route.query;

        const queryForFilter = Object.keys(this.$route.query).length
          ? filtersFromQuery
          : filtersFromStorage;

        // this scenario if reload page, if reload page, the user might change interval (change url query param), so we must set date range to custom
        if (Object.keys(filtersFromQuery).length) {
          const filterQueryFrom = filtersFromQuery['dateRange.interval.from'];
          const filterQueryTo = filtersFromQuery['dateRange.interval.to'];

          const filtersFromStorageFrom = filtersFromStorage?.dateRange?.interval?.from;
          const filtersFromStorageTo = filtersFromStorage?.dateRange?.interval?.to;

          if (
            filterQueryFrom !== filtersFromStorageFrom ||
            filterQueryTo !== filtersFromStorageTo
          ) {
            this.filter.dateRange = this.calculateDateRangeFilter(filterQueryFrom, filterQueryTo);
            ssStore(this.getStoredFilterKey(), { ...this.$route.query, ...this.filter });
          } else {
            this.filter.dateRange = filtersFromStorage.dateRange;
          }
        }

        this.resetFilterModel();
        this.updateFilter(queryForFilter);
        this.fetchCampaigns();
      });
    },
    async created() {
      this.showAdminLoader(true);
      this.debounceFilterQuery = debounce(() => {
        this.fetchCampaigns();
      }, 100);

      const { field, direction } = this.$route.query;
      if (field && direction) {
        this.sorting = { field, direction: parseInt(direction, 10) };
      }

      const { page, limit } = this.$route.query;
      if (page && limit) {
        this.$nextTick(() => {
          this.paginationChange({ page: parseInt(page, 10), limit: parseInt(limit, 10) });
        });
      }
      await this.loadDomains();
    },
    beforeDestroy() {
      document.querySelector('html').removeEventListener('click', this.hideArchivedStatusPopover);
    },
    methods: {
      ...mapMutations(['showAdminLoader']),
      async loadDomains() {
        const {
          data: { accountDomains },
        } = await this.$apollo.query({
          query: GET_ACCOUNT_DOMAINS,
        });
        this.domains = accountDomains;
        this.loadingDomainList = false;

        const selectedDomainId = this.filterModel.domainIds;

        if (!selectedDomainId) return;
        const { _id: key, domain: text } =
          this.domains.find((domain) => domain._id === selectedDomainId) || {};

        const value = key ? { key, text, value: key } : null;

        this.setFilterModel({ key: 'domainIds', eventKey: 'value' }, value);
      },
      lazySearch: debounce(function (event) {
        this.pagination.page = 1;
        this.updateBrowserUrl({ ...this.pagination, ...this.filter });
        this.setFilterModel({ key: 'search' }, event);
        this.$store.commit('updateTablePagination', this.pagination);
      }, 300),
      setFilterModel({ key, eventKey, exclude = [] }, event) {
        if (exclude.length) event = omit(event, ...exclude);

        let filterValue;

        // convert custom date format to always YYYY-MM-DD
        if (key === 'dateRange' && event.key === 'custom') {
          event.interval.from = this.convertDateFormat(event.interval.from);
          event.interval.to = this.convertDateFormat(event.interval.to);
        }

        if (key === 'search' || key === 'dateRange') {
          filterValue = event?.[eventKey] ?? event;
        } else {
          filterValue = event?.[eventKey] === undefined ? null : event?.[eventKey];
        }

        update(this.filter, key, () => filterValue);
        update(this.filterModel, key, () => event);
      },
      resetFilterModel() {
        const { dateRange: filterRange } = this.filter;
        const dateRange = filterRange ?? this.dateRangeDefaultValue.dateRange;
        const additionalFields = { dateRange };
        this.filter = initFilter({ additionalFields });
        this.filterModel = initFilterModel({ additionalFields });
      },
      paginationChange({ page, limit }) {
        this.pagination = { page, limit };
        this.fetchCampaigns();
        this.updateBrowserUrl({ ...this.pagination, ...this.filter });
        this.$store.commit('updateTablePagination', this.pagination);
      },
      // If delete reset hadItem from table
      fetchCampaigns(isDelete = false) {
        if (this.loadingDomainList) return;
        this.isCampaignDelete = isDelete;
        this.loadingCampaigns = true;
        this.$apollo.queries.fetchCampaignData.refresh();
      },
      duplicateCampaign(id) {
        this.loadingCampaigns = true;
        const { templateType } = this.campaigns.find(({ _id }) => _id === id);
        this.$apollo
          .mutate({
            mutation: COPY_CAMPAIGN,
            variables: {
              campaignId: id,
              templateType,
            },
          })
          .then(({ data: { copyCampaign } }) => {
            this.$bus.$emit('deselectAllTable');
            if (copyCampaign.success) {
              this.$router.push({
                name: 'campaign_variants',
                params: { id: copyCampaign.campaignId },
              });
            } else {
              this.$notify({
                type: 'error',
                text: this.$t('notifications.oldCampaignCopyError'),
              });
            }
          });
      },
      deleteCampaignOperations(ids) {
        this.loadingCampaigns = true;
        this.$apollo
          .mutate({
            mutation: DELETE_CAMPAIGNS,
            variables: {
              campaignIds: ids,
              permanent: false,
            },
          })
          .then(() => {
            this.selectedCampaignIds = [];
            this.$bus.$emit('deselectAllTable');
            this.fetchCampaigns(true);
            this.$modal.hide('dialog');
          });
      },
      archiveCampaignsOperation(ids) {
        this.loadingCampaigns = true;

        this.$apollo
          .mutate({
            mutation: ARCHIVE_CAMPAIGNS,
            variables: {
              campaignIds: ids,
            },
          })
          .then(({ data: { archiveCampaigns } }) => {
            const { numberOfArchivedCampaigns } = archiveCampaigns;
            if (numberOfArchivedCampaigns - ids.length === 0) {
              this.showArchivedStatusPopover();
            }
            this.selectedCampaignIds = [];
            this.$bus.$emit('deselectAllTable');
            this.fetchCampaigns(true);
            this.$modal.hide('dialog');
          });
        track('campaignsArchived', { ids, count: ids.length });
      },
      getDialogDefaultParams({ text }) {
        return {
          text,
          buttons: [
            {
              title: this.$t('yes'),
              class: getBrandedClassString({ primary: true }, 'mr-3'),
              handler: () => null,
            },
            {
              title: this.$t('cancel'),
              class: getBrandedClassString({ secondary: true }),
              default: true,
            },
          ],
        };
      },
      async restoreCampaigns(ids) {
        try {
          await this.$apollo.query({
            query: RESTORE_CAMPAIGNS,
            variables: {
              campaignIds: ids,
            },
          });
          this.$notify({
            group: 'om-toast',
            data: {
              toast: {
                color: 'success',
              },
            },
          });
          track('campaignsRestored', { ids, count: ids.length });
        } catch (e) {
          this.$notify({
            group: 'om-toast',
            data: {
              toast: {
                color: 'danger',
              },
            },
          });
        }

        this.$bus.$emit('deselectAllTable');
        this.fetchCampaigns(true);
      },
      isInExperiment(ids) {
        const toBeDeletedCampaigns = this.campaigns.filter((campaign) =>
          ids.includes(campaign._id),
        );
        return toBeDeletedCampaigns.some((campaign) => campaign.currentExperimentId);
      },
      archiveCampaignsInExperiment($event) {
        const { campaigns } = $event;
        this.archiveCampaignsOperation(campaigns);
      },
      archiveCampaigns(ids) {
        if (this.isInExperiment(ids)) {
          this.$modal.show('archive-campaign-in-experiment', { campaigns: ids });
        } else {
          const dialogParams = this.getDialogDefaultParams({
            text: this.$t('archiveConfirmationDialog'),
          });
          this.$bus.$emit('deselectAllTable');
          dialogParams.buttons[0].handler = () => this.archiveCampaignsOperation(ids);
          this.$modal.show('dialog', dialogParams);
        }
      },
      deleteCampaignsInExperiment($event) {
        const { campaigns } = $event;
        this.deleteCampaignOperations(campaigns);
      },
      deleteCampaigns(ids) {
        if (this.isInExperiment(ids)) {
          this.$modal.show('delete-campaign-in-experiment', { campaigns: ids });
        } else {
          const dialogParams = this.getDialogDefaultParams({
            text: this.$t('confirmationDialog'),
          });
          dialogParams.buttons[0].handler = () => this.deleteCampaignOperations(ids);
          this.$modal.show('dialog', dialogParams);
        }
      },
      removeEmptyQueryParams(query) {
        Object.keys(query).forEach((key) => {
          if (!query[key] || (Array.isArray(query[key]) && !query[key].length)) {
            delete query[key];
          }
        });
      },
      updateFilter(query) {
        Object.keys(query ?? {}).forEach((queryKey) => {
          const keyMap = this.filterModelKeyMap.find(({ key: mapKey }) => mapKey === queryKey);
          if (keyMap) {
            const { key, eventKey, option } = keyMap;
            const value = this[option]?.find?.((item) => item.value === query[key]) ?? query[key];
            this.setFilterModel({ key, eventKey }, value);
          }
        });
      },
      updateBrowserUrl(query) {
        if (this.embedded) return;

        this.removeEmptyQueryParams(query);
        const params = Object.keys(query)
          .map((key) => {
            if (key === 'dateRange') {
              return `${encodeURIComponent('dateRange.interval.from')}=${encodeURIComponent(
                query[key].interval.from,
              )}&${encodeURIComponent('dateRange.interval.to')}=${encodeURIComponent(
                query[key].interval.to,
              )}`;
            }
            // prevent dateRange duplication
            if (key.includes('dateRange')) return;

            return `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`;
          })
          .filter((item) => item)
          .join('&');
        const userId = getAccountIdFromCookie();
        window.history.replaceState({}, 'om-multi-campaigns', `/${userId}/campaigns?${params}`);
      },
      openScheduleModal(campaign) {
        this.currentCampaign = { ...campaign };
        this.$nextTick(() => {
          this.$modal.show('schedule');
        });
      },
      deleteScheduleFromCampaigns() {
        this.deleteSchedule(this.fetchCampaigns);
      },
      async changeCampaignPriority(priorityData) {
        await this.changeCampaignSetting(
          'priority',
          priorityData.priority,
          priorityData.campaignId,
          this.fetchCampaigns,
        );
      },
      async changeCampaignName({ campaignId, newCampaignName }) {
        await this.changeCampaignSetting('name', newCampaignName, campaignId, this.fetchCampaign);
      },
      getStoredFilterKey() {
        return `${SESSION_FILTER_KEY_PREFIX}:${getAccountIdFromCookie()}`;
      },
      showArchivedStatusPopover() {
        const userId = getAccountIdFromCookie();
        const uniqueArchiveStatusCookieKey = `${COOKIE_CAMPAIGN_ARCHIVE_FLAG}-${userId}`;
        const notArchivedBefore = !this.getCookie(uniqueArchiveStatusCookieKey);
        if (notArchivedBefore) {
          document.querySelector('html').addEventListener('click', this.hideArchivedStatusPopover);
          this.showArchivedCampaignsPopover = true;
          this.setCookie(uniqueArchiveStatusCookieKey, 1, 180);
        }
      },
      hideArchivedStatusPopover() {
        this.showArchivedCampaignsPopover = false;
      },
      handleEmptyStateCTAClick() {
        track('campaignEmptyStateCTAClicked');
        this.$router.push(this.routeCampaignCreate);
      },
      campaignEmptyStateShowed() {
        track('campaignEmptyStateShow');
      },
    },
  };
</script>
